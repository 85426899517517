import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"
import Icon8 from "../../../static/images/partners/members/aws.svg"
import Icon9 from "../../../static/images/partners/members/microsoft-partner.svg"
import { FaArrowCircleRight } from "react-icons/fa"

const PartnerGCP = () => {
  const data = useStaticQuery(graphql`
    query Images_partnergcp {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Especialistas en Google Cloud | Qualoom</title>
        <meta name="title" content="Especialistas en Google Cloud | Qualoom"/>
        <meta name="description" content="Advanced Consulting Partner de Amazon Web Services. Implantación y mantenimiento de infraestructuras Google Cloud. ¡Infórmate!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/"/>
        <meta property="og:title" content="Especialistas en Google Cloud | Qualoom"/>
        <meta property="og:description" content="Advanced Consulting Partner de Amazon Web Services. Implantación y mantenimiento de infraestructuras Google Cloud. ¡Infórmate!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/"/>
        <meta property="twitter:title" content="Especialistas en Google Cloud | Qualoom"/>
        <meta property="twitter:description" content="Advanced Consulting Partner de Amazon Web Services. Implantación y mantenimiento de infraestructuras Google Cloud. ¡Infórmate!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Google Cloud", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/partner-google-cloud/", "name": "Google Cloud"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Google Cloud</h1>
              <p>Partner de Google Cloud con amplios conocimientos en ecosistemas Cloud, despliegue de cargas de trabajo y migraciones a la nube, ponemos a disposición de nuestros clientes un equipo de profesionales altamente cualificados para ejecutar proyectos sobre este proveedor.​</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué es Google Cloud Platform?</h2><hr></hr>
          <p><b>Google Cloud Platform se trata de la suite de infraestructuras y servicios que Google</b> utiliza a nivel interno y, ahora, disponible para cualquier empresa, de tal forma que sea aplicable a multitud de procesos empresariales como lo son Amazon Web Services y Microsoft Azure.</p><br/>
          <h3>Las ventajas de AWS para tu negocio</h3>
          <ul>
            <li><b>Facilidad de uso. </b>Está diseñada para poder hospedar cualquier tipo de aplicación de forma rápida y segura.</li>
            <li><b>Flexibilidad. </b>Puedes seleccionar la plataforma de desarrollo o el modelo de programación que se adapte mejor a tu negocio.</li>
            <li><b>Rentabilidad. </b>AWS ofrece precios bajos por uso, los únicos gastos serán de aquellos servicios que quieras contratar.</li>
            <li><b>Alta escalabilidad. </b>Con las herramientas de Auto Scaling y Elastic Load Balancing, tu aplicación podrá ampliarse o reducirse según la demanda.</li>
            <li><b>Seguridad. </b>AWS cuenta con certificaciones y acreditaciones para administrar las infraestructuras de las empresas de manera segura y duradera.</li>
          </ul><br/>
          <h3>Infraestructuras Cloud en otras plataformas</h3>
          <div className="cloud-platform bg-white">
            <a href="/cloud-infraestructura/partner-advanced-aws/" className="cloud-platform-icon"><img src={Icon8} alt="Partner Advanced AWS"></img><p>Partner Advanced AWS</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
            <a href="/cloud-infraestructura/partner-microsoft-azure/" className="cloud-platform-icon"><img src={Icon9} alt="Partner Microsoft Azure"></img><p>Partner Microsoft Azure</p><div><span>Descubre más</span><FaArrowCircleRight size={15}/></div></a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerGCP